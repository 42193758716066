<template>
  <div class="flex flex-wrap">
    <basket />
  </div>
</template>
<script>
import basket from '@/components/basket.vue'

export default {
  name: 'cart',
  props: {

  },
  components: {
    basket
  },
  data: function(){
    return {

    }
  }
}
</script>

<style scoped>

</style>
