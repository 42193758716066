<template>
  <div>

    <div class="mx-auto justify-center object-contain p-1 w-full" v-for="image in images" :key="image">
      <img :src=image />
    </div>

  </div>
</template>
<script>
export default {
  name: 'postImage',
  props: {
    images: {
      type: Array,
      required: true
    }
  }
}
</script>
