import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import News from '../views/News.vue'
import Gaff_60 from '../views/gaff-60.vue'
import Gaff_tkl from '../views/gaff-tkl.vue'
import Store from '../views/store.vue'
import Cart from '../views/cart.vue'
import Success from '../views/orderSuccess.vue'
import Cancel from '../views/orderCancel.vue'
import Interest_Check from '../views/interest-check.vue'
import Raffle from '../views/raffle.vue'
import RaffleSuccess from '../views/raffleSuccess.vue'


Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/gaff_60',
    name: 'gaff. 60',
    component: Gaff_60
  },
  {
    path: '/gaff_tkl',
    name: 'gaff. TKL',
    component: Gaff_tkl
  },
  {
    path: '/store',
    name: 'store',
    component: Store
  },
  {
    path: '/cart',
    name: 'cart',
    component: Cart
  },
  {
    path: '/success',
    name: 'order success',
    component: Success
  },
  {
    path: '/cancel',
    name: 'order cancel',
    component: Cancel
  },
  {
    path: '/raffle',
    name: 'raffle',
    component: Raffle
  },
  {
    path: '/raffleSuccess',
    name: 'raffle success',
    component: RaffleSuccess
  },
  {
    path: '/interest_check',
    name: 'interest check',
    component: Interest_Check
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
