<template>
  <div class="w-10/12 mx-auto p-2 mt-4 mb-12 border-b border-gray-400 overflow-hidden">
    <div class="heading float-left">
      <h4 class="title text-4xl">{{ title }}</h4>
      <p class="date align-baseline text-sm">{{ date }}</p>
    </div>
    <div class="content float-left mt-4 mb-4">
      <div v-html="content"></div>
      <postImage :images="images" />
    </div>
  </div>
</template>
<script>
import postImage from '@/components/postImage.vue'

export default {
  name: 'newsPost',
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    images: {
      type: Array,
      required: false
    }
  },
  components: {
    postImage
  }
}
</script>
