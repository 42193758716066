<template>
  <div class="news text-lg md:text-2xl pl-2 pr-2">
    <div v-if="this.$store.state.raffleOpen" class="flex flex-wrap h-full justify-center items-center">
      <div class="w-full lg:w-1/2 object-contain p-1">
        <form class="p-8">
          <div class="mb-8 pb-8 border-b border-gray-400 items-center">

            <label class="block text-gray-700 text-2xl font-normal mb-2">
              BASE KIT.
            </label>
            <label class="block text-gray-700 text-xl font-normal mb-2">
              £322
            </label>

            <label class="block text-gray-700 text-xl font-normal mb-2 mt-4">
              Case Colour.
            </label>
            <div class="radios">
              <input type="radio" name="caseColourRadio" value="blue" id="r1" checked="checked" v-model="payload.baseKit.caseColour" v-on:change="updateImage"/>
              <label class="radio colourSelect RAL5013 text-base w-1/2 inline-block rounded h-10 hover:opacity-50" for="r1"></label>
              <input type="radio" name="caseColourRadio" value="grey" id="r2" v-model="payload.baseKit.caseColour" v-on:change="updateImage"/>
              <label class="radio colourSelect PANTONE-11C text-base w-1/2 inline-block rounded h-10 hover:opacity-50" for="r2"></label>
            </div>

            <label class="block text-gray-700 text-xl font-normal mb-2 mt-4">
              Plate Colour.
            </label>
            <div class="radios">
              <input type="radio" name="plateColourRadio" value="blue" id="r3" checked="checked" v-model="payload.baseKit.plateColour" v-on:change="updateImage"/>
              <label class="radio colourSelect RAL5013 text-base w-1/2 inline-block rounded h-10 hover:opacity-50" for="r3"></label>
              <input type="radio" name="plateColourRadio" value="grey" id="r4" v-model="payload.baseKit.plateColour" v-on:change="updateImage"/>
              <label class="radio colourSelect PANTONE-11C text-base w-1/2 inline-block rounded h-10 hover:opacity-50" for="r4"></label>
            </div>

            <label class="block text-gray-700 text-xl font-normal mb-2 mt-4">
              Plate Style.
            </label>
            <div class="radios">
              <input type="radio" name="plateStyleRadio" value="sprung" id="r5" checked="checked" v-model="payload.baseKit.plateStyle" v-on:change="updateImage"/>
              <label class="radio plateSelect inline-block text-center w-1/2 text-base pt-2 pb-2 h-10 bg-transparent rounded border border-gray-500 text-gray-700 hover:bg-gray-500 hover:text-gray-800" for="r5">sprung.</label>
              <input type="radio" name="plateStyleRadio" value="solid" id="r6" v-model="payload.baseKit.plateStyle" v-on:change="updateImage"/>
              <label class="radio plateSelect inline-block text-center w-1/2 text-base pt-2 pb-2 h-10 bg-transparent rounded border border-gray-500 text-gray-700 hover:bg-gray-500 hover:text-gray-800" for="r6">solid.</label>
            </div>

          </div>

          <div class="mb-8 pb-8 border-b border-gray-400 items-center">

            <button v-if="!extras" class="w-full text-lg font-light p-2 bg-transparent rounded border border-gray-500 text-gray-700 hover:bg-gray-500 hover:text-gray-800" @click='extras = true' type="button">add extras.</button>

            <label v-if="extras" class="block text-gray-700 text-2xl font-normal mb-2">
              EXTRAS.
            </label>

            <div v-if="extras" class="">

              <label class="block text-gray-700 text-xl font-normal mb-1 mt-4">
                Extra Plate.
              </label>
              <label class="block text-gray-700 text-lg font-normal mb-2 mt-2">
                £36
              </label>
              <select class="block appearance-none w-full text-lg font-light p-2 bg-transparent rounded border border-gray-500 text-gray-700 hover:bg-gray-500 hover:text-gray-800" id="extra-plate" v-model="payload.extras.extraPlate" v-on:change="updateImage">
                <option :value="false">no.</option>
                <option :value="true">yes.</option>
              </select>

              <div v-if="payload.extras.extraPlate">

                <label class="block text-gray-700 text-xl font-normal mb-2 mt-4">
                  Extra Plate Style.
                </label>
                <div class="radios">
                  <input type="radio" name="extraPlateStyleRadio" value="sprung" id="r7" checked="checked" v-model="payload.extras.plateStyle" v-on:change="updateImage"/>
                  <label class="radio plateSelect inline-block text-center w-1/2 text-base pt-2 pb-2 h-10 bg-transparent rounded border border-gray-500 text-gray-700 hover:bg-gray-500 hover:text-gray-800" for="r7">sprung.</label>
                  <input type="radio" name="extraPlateStyleRadio" value="solid" id="r8" v-model="payload.extras.plateStyle" v-on:change="updateImage"/>
                  <label class="radio plateSelect inline-block text-center w-1/2 text-base pt-2 pb-2 h-10 bg-transparent rounded border border-gray-500 text-gray-700 hover:bg-gray-500 hover:text-gray-800" for="r8">solid.</label>
                </div>

                <label class="block text-gray-700 text-xl font-normal mb-2 mt-4">
                  Extra Plate Colour.
                </label>
                <div class="radios">
                  <input type="radio" name="extraPlateColourRadio" value="blue" id="r9" checked="checked" v-model="payload.extras.plateColour" v-on:change="updateImage"/>
                  <label class="radio colourSelect RAL5013 text-base w-1/2 inline-block rounded h-10 hover:opacity-50" for="r9"></label>
                  <input type="radio" name="extraPlateColourRadio" value="grey" id="r10" v-model="payload.extras.plateColour" v-on:change="updateImage"/>
                  <label class="radio colourSelect PANTONE-11C text-base w-1/2 inline-block rounded h-10 hover:opacity-50" for="r10"></label>
                </div>

              </div>

              <label class="block text-gray-700 text-xl font-normal mb-1 mt-4">
                Extra PCB.
              </label>
              <label class="block text-gray-700 text-lg font-normal mb-2 mt-2">
                £32
              </label>
              <select class="block appearance-none w-full text-lg font-light p-2 bg-transparent rounded border border-gray-500 text-gray-700 hover:bg-gray-500 hover:text-gray-800" id="extra-pcb" v-model="payload.extras.extraPCB" v-on:change="updateImage">
                <option :value="false">no.</option>
                <option :value="true">yes.</option>
              </select>
            </div>

          </div>

          <div class="mb-8 pb-8 border-b border-gray-400 items-center">

            <label class="block text-gray-700 text-2xl font-normal mb-2">
              DETAILS.
            </label>

            <input class="mt-8 block appearance-none w-full text-lg font-light p-2 bg-transparent rounded border border-gray-500 text-gray-700 hover:bg-gray-500 hover:text-gray-100" id="fullName" v-model="payload.details.name" type="text" placeholder="full name.">
            <input class="mt-8 block appearance-none w-full text-lg font-light p-2 bg-transparent rounded border border-gray-500 text-gray-700 hover:bg-gray-500 hover:text-gray-100" id="email" v-model="payload.details.email" type="text" placeholder="email.">

          </div>

          <div class="mb-8 pb-8 border-b border-gray-400 items-center">

            <button class="w-full text-lg font-light p-2 bg-transparent rounded border border-gray-500 text-gray-700 hover:bg-gray-500 hover:text-gray-100" @click='enterRaffle' type="button">enter raffle.</button>

          </div>

          <div>
            <p class="text-base text-blue-800 pb-2 pl-1">* shipping cost will be calculated at checkout.</p>
            <p class="text-base text-blue-800 pb-2 pl-1">* renders are not an accurate representation of the final product colours.</p>
          </div>

          <div v-if="errors.length">
            <ul class="text-base text-red-800 pb-2">
              <li class="pl-1">*</li>
              <li class="pl-4" v-for="error in errors" :key="error">{{ error }}</li>
           </ul>
          </div>
        </form>
      </div>
      <div class="w-full lg:w-1/2 object-contain p-1">
        <img v-if="this.imgSrcBaseKit != null" :src="imgSrcBaseKit" />
        <img v-if="this.imgSrcExtraPlate != null" :src="imgSrcExtraPlate" />
        <img v-if="this.payload.extras.extraPCB" src="@/assets/images/renders/raffle/extras/PCB.png" />
      </div>
    </div>
    <div v-else class="flex justify-center">
      <p class="opacity-50 text-center">no raffles are currently open.</p>
    </div>
  </div>
</template>
<script>

const axios = require('axios');

// sprung baseKit.
import allBlue from '@/assets/images/renders/raffle/basekit-sprung/allBlue.png'
import allGrey from '@/assets/images/renders/raffle/basekit-sprung/allGrey.png'
import greyTopBluePlate from '@/assets/images/renders/raffle/basekit-sprung/greyCaseBluePlate.png'
import blueTopGreyPlate from '@/assets/images/renders/raffle/basekit-sprung/blueCaseGreyPlate.png'

// solid baseKit.
import blueSolid from '@/assets/images/renders/raffle/basekit-solid/allBlue.png'
import greySolid from '@/assets/images/renders/raffle/basekit-solid/allGrey.png'
import greyTopBlueSolid from '@/assets/images/renders/raffle/basekit-solid/greyCaseBluePlate.png'
import blueTopGreySolid from '@/assets/images/renders/raffle/basekit-solid/blueCaseGreyPlate.png'

// extra Plate - sprung and solid.
import bluePlateSprung from '@/assets/images/renders/raffle/extras/blueSprung.png'
import greyPlateSprung from '@/assets/images/renders/raffle/extras/greySprung.png'
import bluePlateSolid from '@/assets/images/renders/raffle/extras/blueSolid.png'
import greyPlateSolid from '@/assets/images/renders/raffle/extras/greySolid.png'

export default {
  name: 'news',
  components: {

  },
  mounted: function() {

  },
  data: function() {
    return {
      extras: false,

      errors: [],

      imgSrcBaseKit: null,
      imgSrcExtraPlate: null,

      payload: {
        baseKit: {
          caseColour: null,
          plateColour: null,
          plateStyle: null,
        },
        extras: {
          extraPlate: false,
          plateStyle: null,
          plateColour: null,
          extraPCB: false,
        },
        details: {
          name: null,
          email: null,
        },
        token: null
      }
    }
  },
  methods: {
    updateImage: function(){
      if(this.payload.baseKit.caseColour == 'blue' && this.payload.baseKit.plateColour == 'blue') {
        if(this.payload.baseKit.plateStyle == 'solid') {
          this.imgSrcBaseKit = blueSolid;
        } else if(this.payload.baseKit.plateStyle == 'sprung'){
          this.imgSrcBaseKit = allBlue;
        }
      }

      if(this.payload.baseKit.caseColour == 'grey' && this.payload.baseKit.plateColour == 'grey') {
        if(this.payload.baseKit.plateStyle == 'solid') {
          this.imgSrcBaseKit = greySolid;
        } else if(this.payload.baseKit.plateStyle == 'sprung'){
          this.imgSrcBaseKit = allGrey;
        }
      }

      if(this.payload.baseKit.caseColour == 'grey' && this.payload.baseKit.plateColour == 'blue') {
        if(this.payload.baseKit.plateStyle == 'solid') {
          this.imgSrcBaseKit = greyTopBlueSolid;
        } else if(this.payload.baseKit.plateStyle == 'sprung'){
          this.imgSrcBaseKit = greyTopBluePlate;
        }
      }

      if(this.payload.baseKit.caseColour == 'blue' && this.payload.baseKit.plateColour == 'grey') {
        if(this.payload.baseKit.plateStyle == 'solid'){
          this.imgSrcBaseKit = blueTopGreySolid;
        } else if(this.payload.baseKit.plateStyle == 'sprung'){
          this.imgSrcBaseKit = blueTopGreyPlate;
        }
      }

      if(this.payload.extras.extraPlate){
        if(this.payload.extras.plateStyle == 'solid'){
          if(this.payload.extras.plateColour ==  'blue'){
            this.imgSrcExtraPlate = bluePlateSolid;
          } else if(this.payload.extras.plateColour == 'grey'){
            this.imgSrcExtraPlate = greyPlateSolid
          }
        } else {
          if(this.payload.extras.plateColour == 'blue'){
            this.imgSrcExtraPlate = bluePlateSprung;
          } else if(this.payload.extras.plateColour == 'grey'){
            this.imgSrcExtraPlate = greyPlateSprung;
          }
        }
      }

    },
    enterRaffle: function(){
      this.errors = [];
      this.checkForm();
      if(this.errors.length == 0){
        if(!this.payload.extras.extraPlate){
          this.payload.extras.plateStyle = null;
          this.payload.extras.plateColour = null;
        }
        this.$recaptcha("raffle").then(token => {
          this.payload.token = token;
          axios({
            method: 'post',
            url: '',
            data: this.payload
          }).then(res => {
            if(res.status == '200') {
              this.$router.push('raffleSuccess');
            }
          }).catch(err => {
            if(err){
              this.errors.push("entry not submitted, possible duplicate entry.");
            }
          });
        });
      }
    },
    checkForm: function(){

      this.errors = [];

      if(!this.payload.baseKit.caseColour){
        this.errors.push("base kit case colour required.");
      }

      if(!this.payload.baseKit.plateColour){
        this.errors.push("base kit plate colour required.");
      }

      if(!this.payload.baseKit.plateStyle){
        this.errors.push("base kit plate style required.");
      }

      if(!this.payload.extras.plateStyle){
        if(this.payload.extras.plateColour){
          this.errors.push("you've selected a extra plate colour, but haven't picked a style.");
        }
      }

      if(!this.payload.extras.plateColour){
        if(this.payload.extras.plateStyle){
          this.errors.push("you've selected an extra plate style, but haven't picked a colour.");
        }
      }

      if(this.payload.extras.extraPlate && !this.payload.extras.plateColour && !this.payload.extras.plateStyle){
        this.errors.push("you've added an extra plate but not selected a style or colour.");
      }

      if (!this.payload.details.name) {
        this.errors.push("name required.");
      }

      if (!this.payload.details.email) {
        this.errors.push('email required.');
      } else if (!this.validEmail(this.payload.details.email)) {
        this.errors.push('valid email required.');
      }

    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }

  }
}
</script>

<style scoped>

.radios input[type=radio]{
    display:none;
}

.radio {
  opacity: 0.75;
}

.radios input[type=radio]:checked + .radio{
  opacity: 1;
  box-shadow: 0 1px 5px rgba(143, 143, 143, 0.5)  ;
	-webkit-box-shadow: 0 1px 5px rgba(143, 143, 143, 0.5)  ;
	-moz-box-shadow: 0 1px 5px rgba(143, 143, 143, 0.5)  ;
}

.colourSelect {
  width: calc(50% - .5em);
  margin-right: 0.5rem;
}

.plateSelect {
  width: calc(50% - .5em);
  margin-right: 0.5rem;
}

.RAL5013 {
  background-color: #193153;
}

.PANTONE-11C {
  background-color: #53565A;
}
</style>
