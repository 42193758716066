<template>
  <div class="interestCheck">
    <div class="flex flex-wrap h-screen">
      <iframe id='gform' class="mx-auto w-full" src="https://docs.google.com/forms/d/e/1FAIpQLScCI2yOLZSPkvQVFt32v0yZg3mT2vyaeuvwIJCHf1qIcmIphA/viewform?embedded=true" height="2005" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  </div>
</template>
<script>
export default {
  name: 'interest check',
  components: {

  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    }
  },
  mounted(){
    var load = 0;

    document.getElementById('gform').onload = function(){
      /*Execute on every reload on iFrame*/
      load++;
      if(load > 1){
        /*Second reload is a submit*/
        window.scrollTo(0,0);
      }
    }

  }
}
</script>
<style scoped>

</style>
