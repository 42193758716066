<template>
  <div class="home">
    <div class="flex flex-wrap h-full">
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {

  }
}
</script>
<style scoped>

.products img:hover {
  opacity: 0.6;
}

</style>
