<template>
  <div class="text-lg md:text-2xl pl-4 pr-4">
    <div v-if="isHidden" class="flex flex-wrap h-full items-center">

      <div class="w-full lg:w-1/2 object-contain p-1">
        <img src="@/assets/images/SVG/regular-view/60.svg" />
      </div>

      <div class="w-full lg:w-1/2 object-contain p-1">
        <img class="heading w-1/4 pb-4" src="@/assets/images/SVG/headings/gaff60.svg" />
        <div class="opacity-75">
          <p>The gaff. 60 is our debut keyboard.
            <br />A clean unibody design with a either a sprung or solid plate and internal weight - all made from the highest quality materials.
            <br />The PCB is a unique version of the Plain60-C compatible with QMK Firmware and VIA Configurator, designed by <a class="externalLink" href="https://maartenwut.com/">Maartenwut.</a>
            <br />
            <br />All gaff. 60 units have been sold and will ship in late November 2020.</p>
            <br>
        </div>
        <button name="gallery" v-on:click="isHidden = false">gallery.</button>
      </div>

    </div>

    <div v-if="!isHidden" class="flex flex-wrap h-full items-center">
      <div class="w-full object-contain p-1">
        <button name="close" v-on:click="isHidden = true" type="is-text"><font-awesome-icon :icon="['fas', 'times-circle']" /></button>
        <vue-glide :perView="1">
          <template slot="control">
            <button data-glide-dir="<">prev</button>
            <button class="float-right" data-glide-dir=">">next</button>
          </template>
          <vue-glide-slide>
            <img src="@/assets/images/renders/60/1.png" />
          </vue-glide-slide>
          <vue-glide-slide>
            <img src="@/assets/images/renders/60/2.png" />
          </vue-glide-slide>
          <vue-glide-slide>
            <img src="@/assets/images/renders/60/3.png" />
          </vue-glide-slide>
          <vue-glide-slide>
            <img src="@/assets/images/renders/60/4.png" />
          </vue-glide-slide>
          <vue-glide-slide>
            <img src="@/assets/images/renders/60/5.png" />
          </vue-glide-slide>
          <vue-glide-slide>
            <img src="@/assets/images/renders/60/6.png" />
          </vue-glide-slide>
        </vue-glide>
      </div>
    </div>
  </div>
</template>
<script>
import { Glide, GlideSlide } from 'vue-glide-js'

export default {
  name: 'gaff 60',
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data() {
    return {
      isHidden: true
    }
  }
}
</script>
<style scoped>

</style>
