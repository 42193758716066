<template>
  <div class="text-lg md:text-2xl pl-4 pr-4">
    <div class="flex flex-wrap h-full items-center">

      <div class="w-full lg:w-1/2 object-contain p-1">
        <img src="@/assets/images/SVG/regular-view/tkl.svg" />
      </div>

      <div class="w-full lg:w-1/2 object-contain p-1">
        <img class="heading w-1/4 pb-4" src="@/assets/images/SVG/headings/gaffTKL.svg" />
        <p class="opacity-75">
        The gaff. TKL will feature a 4mm integrated plate, removable weight, and a hotswap PCB with daughterboard.
        <br /><br />There is currently no release timeline.</p>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  name: 'gaff 60',
  components: {

  }
}
</script>
<style scoped>

</style>
