<template>
  <div class="about">
    <div class="flex justify-center">
      <p class="opacity-50 text-center">you shouldn't be here...</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'about',
  components: {

  }
}
</script>
