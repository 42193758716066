<template>
  <div class="news text-lg md:text-2xl pl-2 pr-2">
    <div class="container w-full justify-center">

      <newsPost
        title="The Latest!"
          content="
          <br />
          Hello! It's been a while since our last update, so we wanted to share the latest on what's been going on since the gaff. 60 group buy.
          <br />
          <br />
          // gaff. 60
          <br />
          PCBs are in hand and look great. There has been a slight delay with the case anodising, so we're expecting to take delivery of everything in mid/late November.
          <br />
          Everything is already in place to ship out fairly quickly after we take shipment and are happy with the quality.
          <br />
          <br />
          *If anyone needs to change the address on their order please reach out to us on Discord on via email.
          <br />
          <br />
          // gaff. TKL
          <br />
          We've built two prototype boards, one and in Polycarbonate and one in Aluminium.
          <br />
          After testing both the prototypes we've decided that we won't be making any production cases in Aluminium. The acoustics and feel with an integrated plate in this material doesn't meet our standards.<br />
          The polycarbonate case on the other hand has a brilliant sound and feel. We're looking into providing a larger selection of polycarbonate colours at release.<br />
          We definitely still want to do a TKL in Aluminium with the same aesthetic but it'll need more work to meet a higher standard in other areas. This is hopefully something we'll be working on in the future.
          <br />
          <br />
          We dont currently have a release timeline, but we can say it wont be before the gaff. 60 has shipped.
          <br />
          <br />
          We've had a couple samples made of some accessories and merchandise that we plan on releasing alongside the gaff. TKL. <br />
          Nothing is confirmed yet but we'd love to hear some feedback on what stuff people may like to see.<br /><br />"
        date="23 / 10 / 20"
        :images="[require('@/assets/images/photos/5.jpg'), require('@/assets/images/photos/4.jpg'), require('@/assets/images/photos/3.jpg')]"
      />

      <newsPost
        title="Invoicing Complete! - gaff. 60"
          content="
          <br />
          All invoices have been paid! Thanks to everyone for being so fast.<br />
          It'll be good to have all orders 100% confirmed by Friday July 17th at the latest, so that we can get all the correct details to our manufacturers on Monday July 20th.<br />
          If you do want to make some changes to your order its best to contact me on Discord.
          <br />
          <br />
          - Stats -<br />
          // Base Kits.<br />
          Blue - 35<br />
          Grey - 25<br />
          Sprung - 45<br />
          Solid - 15
          <br />
          <br />
          // Extras.<br />
          PCBs - 32<br />
          Solid - 12<br />
          Sprung - 11
          <br />
          <br />
          We hope to start shipping around October but that could be sooner or later depending on manufacturing time.<br />
          we'll keep posting updates when things change.
          <br />
          <br />
          We've taken some more projects into the prototyping stage now so we'll have info on those to share soon.
          <br />
          <br />
            Thanks again for the continued support."
        date="15 / 07 / 20"
        :images="[require('@/assets/images/renders/60/12.png')]"
      />

      <newsPost
        title="Raffle Closed! - gaff. 60"
          content="
          <br />
          Thank you so much to everyone that submitted an entry to the gaff. 60 raffle. We've had a massive response that we didn't expect!<br />
          We closed out the raffle after being live for 24 hours with just under 400 entries.
          <br />
          <br />
          It would be amazing to let everyone into the group buy, but as our first project we wanted to keep the units something we can manage.<br />
          However, with this massive amount of support we've decided to increase the units to 60 to avoid disappointing as many people as possible.
          <br />
          <br />
          We'd still love to do another round of the gaff. 60 in the future and have lots more projects in the works.
          <br />
          <br />
          Winners will be invoiced on Monday July 13th.
          <br />
          <br />
          Thanks for all the support!
          <br />
          <br />"
        date="11 / 07 / 20"
        :images="[]"
      />

      <newsPost
        title="The Raffle - gaff. 60"
          content="
          <br />
          The raffle will be open July 10th at 3pm GMT (2pm BST, 9am EST, 3pm CET).
          <br />
          There are 30 slots available and will be open for enough time to ensure that everyone in different time zones has the opportunity to enter.
          <br />
          <br />
          Winners will be sent an invoice on July 13th via shopify.
          <br />
          <br />
          Cheers.
          <br />
          <br />"
        date="08 / 07 / 20"
        :images="[]"
      />

      <newsPost
        title="Final prototype and raffle - gaff. 60"
          content=" - Final Prototype. -
          <br />
          The second prototype came out perfect. The colours and the finish from the new manufacturer are great. This will be the model to go into production.
          <br />
          <br />
          - The Raffle -
          <br />
          The raffle will be held on July 10th, with 30 slots available.
          <br />
          The form will stay up for at least 12 hours to ensure that people from different time zones will be able to enter.
          <br />
          It will be strictly one entry per person, any duplicate entries will be removed.
          <br />
          Winners will be emailed an invoice via shopify. These invoices will be valid for 12 hours, If checkouts are abandoned a new entry will be drawn.
          <br />
          If you need extra time to pay your invoice please contact us as soon as possible to ensure you dont lose you slot.
          <br />
          <br />
          The form will include:
          <br />
          - Basekit colour and plate style options.
          <br />
          - Extra plate in either colour and styles.
          <br />
          - Extra PCB.
          <br />
          <br />
          Cheers.
          <br />
          <br />"
        date="01 / 07 / 20"
        :images="[require('@/assets/images/photos/1.jpg'), require('@/assets/images/photos/2.png')]"
      />

      <newsPost
        title="Prototyping and Pricing - gaff. 60"
          content=" - Pricing and Availability. -
          <br />
          After the interest and feedback received, we’ve decided to double the batch size to 30 units. Hopefully doing this ensures people who want to be involved won’t miss out.
          Unfortunately, we have under estimated the cost per unit for a small batch and won’t be able to meet our intended price point.
          Each base kit will cost $400. This includes the case, plate, weight, and all necessary hardware. Shipping costs will depend on location and will be calculated at checkout.
          <br />
          These prices ensure we can run the group buy without taking a loss and continue to deliver future planned projects. Although we couldn’t meet our original intended price we'll still be able to deliver a high quality product at a reasonably low price considering the small batch quantity and materials.
          <br />
          Offering multiple colour choices and extra features like custom silicone feet and an internal brass weight make the cost per unit much higher with a small batch.
          <br />
          <br />
           - Changes and Improvements. -
          <br />
          We’re still waiting for first prototype PCBs to land, but after receiving the first prototype case we’ve made multiple design changes for the second one.
          <br />
          Hopefully the second prototype goes into production next week.
          <br />
          We had some issues with our first manufacturer regarding the machining and finish quality, so we’ve moved to another company we’re hoping to use for the final production.
          <br />
          <br />
          - Sprung Plate
          <br />
          We’ve changed the sprung plate slightly to lessen the flex. The edge channel has been reduces from 2mm to 1mm, and the outer edges around the corners have been removed as they seemed to have little effect. The bridges below the bottom row have been changed to allow the spacebar to have a more even feel.
          <br />
          <br />
          - Base Plate.
          <br />
          We’ve added four extra screws to the base plate to help with acoustics. The size of the silicone feet has been tweaked slightly for aesthetic reasons.
          <br />
          <br />
          Cheers."
        date="27 / 05 / 20"
        :images="[require('@/assets/images/renders/60/8.png'), require('@/assets/images/renders/60/9.png'), require('@/assets/images/renders/60/10.png'), require('@/assets/images/renders/60/11.png')]"
      />

      <newsPost
        title="Interest Check - gaff. 60"
          content="Thank you to anyone who has filled out the interest form or expressed their interest on the geekhack thread!
          <br />
          We're currently waiting on the first prototype to land.
          Unfortunately this prototype doesn't include the integrated weight as it was added to the design too late.
          This first prototype should give a good idea of how the plate design is going to work and what will need to be changed.
          <br />
          A second and hopefully final prototype will be ordered as soon as possible, with some changes having already been made ahead of time.
          Hopefully once that has landed and everything is signed off on we can move onto the group buy stage quickly.
          <br />
          <br />
          - The Group Buy -
          <br />
          Its been expressed many times during the interest check to NOT release on a first come, first served basis.
          <br />
          A raffle system seems to be the favorite way to do a small release, so we've come up with a solution we think will work well.
          <br />
          <br />
          This release was always intended to be a limited batch. We've decided to only run 15 slots
          to ensure we can deliver a high quality product and not overwhelm ourselves.
          We feel its more important to deliver a good product rather than increase the slots just to meet demand.
          <br />
          This will NOT be the only release, if you miss out on the first batch you will definitely have another opportunity not long after.
          <br />
          <br />
          If you have any questions about anything please feel free to contact me on discord.
          <br />
          <br />
          Cheers."
        date="21 / 05 / 20"
      />

    </div>
  </div>
</template>
<script>
import newsPost from '@/components/newsPost.vue'

export default {
  name: 'news',
  components: {
    newsPost
  }
}
</script>
