import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
  state: {
    checkoutOpen: false,
    checkoutInfo: null,
    raffleOpen: false
  },
  plugins: [createPersistedState()],
  mutations: {
    addToCart (state, payload) {
      state.basket.push(payload)
    },
    updateQuantity (state, payload) {
      console.log(state, payload)
    },
    emptyCart (state){
      state.checkoutInfo = null
      state.checkoutOpen = false;
    },
    checkoutOpen(state){
      state.checkoutOpen = true;
    },
    checkoutClose(state){
      state.checkoutOpen = false;
    },
    updateCheckoutInfo(state, payload){
      state.checkoutInfo = payload;
    }
  },
  actions: {
  },
  modules: {
  }
})
