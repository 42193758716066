<template>
  <div class="success">
    <div class="flex justify-center">
      <p class="text-center opacity-50">Entry submitted!<br />
      Winners will receive an invoice when the raffle is closed.</p>
    </div>
  </div>
</template>
<script>
localStorage.clear();
export default {

}
</script>
<style scoped>

</style>
