<template>
  <div class="flex items-center	justify-center">
    <div class="mx-auto w-1/2 md:w-1/4" style="top: 50%; position: absolute; transform: translateY(-50%);">
      <a href="mailto:gio@gaff.haus?subject=Gaff%20Haus%20Inquiry"><img class="logo" src="@/assets/images/gaff.svg" /></a>
      <p style="
        font-size: small;
        align-items: center;
        text-align: center;
        padding-top: 20px;">
        welcome to our haus
      </p>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Logo'
}
</script>

<style scoped>
.logo {
  opacity: 0.9;
  animation: 2s appear;
}

.logo:hover {
  opacity: 0.6;
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
