<template>
  <div class="success">
    <div class="flex justify-center">
      <p class="text-center opacity-50">Order complete, thank you.<br />
      Please check your email for an order confimation.</p>
    </div>
  </div>
</template>
<script>
localStorage.clear();
export default {
  async created (){
    this.$store.commit('emptyCart');
  }
}
</script>
<style scoped>

</style>
