
<template>
  <div v-if="this.$store.state.checkoutOpen == true" class="flex flex-wrap w-full h-full p-4">

    <table class="table-auto w-full text-xl">
      <thead class="font-normal">
        <tr>
          <td class="px-4 py-2">product</td>
          <td class="px-4 py-2">price</td>
          <td class="px-4 py-2">quantity</td>
          <td class="px-4 py-2">total</td>
        </tr>
      </thead>
      <tbody class="font-thin">
        <tr v-for="item in this.checkout.lineItems" :key="item.title" :value="item.quantity">
          <td class="border px-4 py-2">{{ item.title }}</td>
          <td class="border px-4 py-2">{{ item.variant.price }} {{ item.variant.priceV2.currencyCode }}</td>
          <td class="border px-4 py-2">{{ item.quantity }}</td>
          <td class="border px-4 py-2">{{ item.variant.price*item.quantity }} {{ item.variant.priceV2.currencyCode }}</td>
          <td class="px-4 py-2"><a class="opacity-50 hover:opacity-75" v-on:click="removeFromCart(item.id)"><font-awesome-icon :icon="['fas', 'times-circle']" /></a></td>
        </tr>
      </tbody>
    </table>

    <div class="details pt-10">
      <p class="inline text-xl">subtotal: </p>
      <p class="inline text-xl">{{ this.checkout.totalPrice }} {{ this.checkout.currencyCode }}</p>
      <div class="">
        <button class="pl-8 pr-8 text-lg font-light p-2 bg-transparent rounded border border-gray-500 text-gray-700 hover:bg-gray-500 hover:text-gray-100" @click='checkoutLink' type="button">checkout.</button>
        <p class="pt-1 text-lg opacity-50">shipping is calculated at checkout.</p>
        <p class="text-lg opacity-75">*These are group buy items. Be aware these items may take longer than expected to ship.</p>
      </div>
    </div>

  </div>

  <div v-else class="mx-auto">
    <p class="text-center">your cart is empty.</p>
  </div>

</template>
<script>
import Client from 'shopify-buy'
const client = Client.buildClient({
  domain: 'gaff-works.myshopify.com',
  storefrontAccessToken: process.env.VUE_APP_ACCESS_TOKEN
});
export default {
  name: 'basket',
  props: {
  },
  components: {
  },
  methods: {
    fetchdata () {
      client.checkout.fetch(this.$store.state.checkoutInfo.id).then((checkout) => {
        this.checkout = checkout;
        if(this.checkout.lineItems.length == 0){
          this.$store.commit('checkoutClose')
        }
      });
    },
    removeFromCart(id){
      client.checkout.removeLineItems(this.checkout.id, id).then((checkout) => {
        this.fetchdata(checkout.id);
      });
    },
    clearCart: function(){
      this.$store.commit('emptyCart');
      this.fetchdata();
    },
    checkoutLink: function(){
      window.location.href = this.$store.state.checkoutInfo.url;
    }
  },
  async created (){
    this.fetchdata();
  },
  data: function(){
    return {
      checkout: null
    }
  }
}
</script>

<style scoped>

</style>
