<template>
  <!-- <div class="md:p-5">
    <div class="flex flex-wrap h-full items-center">
      <storeProduct v-for="product in this.productList" :key="product.title" :value="product.id"
      :productTitle="product.title"
      :productPrice="product.variants[0].price"
      :imageSrc="product.variants[0].image.src"
      :productDetails="product.description"
      :productSku="product.variants[0].id"
      :inStock="product.availableForSale"
      />
    </div>
  </div> -->
  <div class="storeClosed">
    <div class="flex justify-center">
      <p class="text-center opacity-50">Sorry, we're closed!</p>
    </div>
  </div>
</template>
<script>
// import storeProduct from '@/components/storeProduct.vue'

import Client from 'shopify-buy'

const client = Client.buildClient({
  domain: 'gaff-works.myshopify.com',
  storefrontAccessToken: process.env.VUE_APP_ACCESS_TOKEN
});

export default {
  name: 'gaff 60',
  components: {
    // storeProduct
  },
  props: {

  },
  methods:{
    fetchdata () {
      client.product.fetchAll().then((products) => {
        this.productList = products
      });
    }
  },
  async created (){
    this.fetchdata();
  },
  data: function() {
    return {
      productList: null
    }
  }
}
</script>
<style scoped>

</style>
