<template>
  <div class="container" id="app">
    <Logo />
    <transition
    name="fade"
    mode="out-in"
    >
    <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue'

export default {
  name: 'App',
  components: {
    Logo
  }
}
</script>

<style>
#app {
  background-color: #f2f2f2 !important;
  font-family: bilo, sans-serif !important;
  font-weight: 300 !important;
  font-style: normal !important;
  font-size: 200%;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.4s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>
